import { cloneDeep, set } from 'lodash'

import * as FormsStateActions from './formsState.actions'
import { FormsState } from '../models/formsState'

export type Action = FormsStateActions.All

const initialState = {
  forms: {}
}

const initialFormState = {
  completed: false
}

export function formsStateReducer(
  state: FormsState = initialState,
  action: Action
): FormsState {
  const newState = cloneDeep(state)
  switch (action.type) {
    case FormsStateActions.INIT_FORMS_STATE:
      action.payload.forEach(form =>
        set(newState, ['forms', form.id], cloneDeep(initialFormState))
      )
      return newState
    case FormsStateActions.SET_COMPLETED:
      set(newState, ['forms', action.payload, 'completed'], true)
      return newState
    default:
      return state
  }
}
