import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './services/auth-guard.service'
import { TaskResolver } from './services/task-resolver.service'

import { routeSegment } from './models/routerState'

import { PageHomeComponent } from './components/page/home/page-home.component'
import { PageFormsReviewComponent } from './components/page/forms-review/page-forms-review.component'
import { PageFormStartComponent } from './components/page/form-start/page-form-start.component'
import { PageQuestionComponent } from './components/page/question/page-question.component'
import { PageFormsCompletedComponent } from './components/page/forms-completed/page-forms-completed.component'
import { PageNotFoundComponent } from './components/page/not-found/page-not-found.component'

const appRoutes: Routes = [
  { path: '', component: PageHomeComponent },
  {
    path: ':code',
    canActivate: [AuthGuard],
    resolve: { task: TaskResolver },
    children: [
      {
        path: routeSegment.questionnaires,
        component: PageFormsReviewComponent,
        data: { initial: true }
      },
      {
        path: `${routeSegment.questionnaire}/:formId`,
        component: PageFormStartComponent
      },
      {
        path: `${routeSegment.questionnaire}/:formId/${routeSegment.complete}`,
        component: PageFormsReviewComponent
      },
      {
        path: `${routeSegment.questionnaire}/:formId/${routeSegment.question}`,
        component: PageQuestionComponent
      },
      {
        path: `${routeSegment.questionnaire}/:formId/${
          routeSegment.question
        }/:questionId`,
        component: PageQuestionComponent
      },
      { path: routeSegment.fin, component: PageFormsCompletedComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule],
  providers: [TaskResolver]
})
export class AppRoutingModule {}
