import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Router, RoutesRecognized } from '@angular/router'

import { AppState } from '../store'
import { LocaleService } from '../services/locale.service'
import { NavigationService } from '../services/navigation.service'
import { Destination } from '../models/destination'

@Component({
  selector: 'capto-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  childPage = false

  constructor(
    public translate: LocaleService,
    private navigation: NavigationService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store.select('destination').subscribe((destination: Destination) => {
      if (destination.length) {
        this.navigation.navigate(destination)
      }
    })
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.childPage = !!val.state.root.firstChild.url.length
      }
    })
  }
}
