import { Component, HostBinding, OnInit } from '@angular/core'

import { TaskService } from '../../../services/task.service'
import { NavigationService } from '../../../services/navigation.service'
import { routerTransition } from '../../../animation/router.animation'
import { Form, FormType } from '../../../models/form'
import { FormsState } from '../../../models/formsState'
import { Patient } from '../../../models/patient'

@Component({
  selector: 'capto-page-completed',
  templateUrl: './page-forms-completed.component.html',
  styleUrls: ['./page-forms-completed.component.scss'],
  animations: [routerTransition()]
})
export class PageFormsCompletedComponent implements OnInit {
  @HostBinding('@routerTransition')
  @HostBinding('class.page-wrapper')
  true

  forms: Form[] = []
  formType = FormType
  formsState: FormsState
  titleParam = {}

  constructor(
    private navigationService: NavigationService,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    // @TODO : redirect if there’s an incomplete form?
    this.taskService.getForms().subscribe(forms => (this.forms = forms))
    this.taskService
      .getFormsState()
      .subscribe(formsState => (this.formsState = formsState))
    this.taskService.getPatient().subscribe(
      (patient: Patient): void => {
        if (patient) {
          this.titleParam = {
            firstname: patient.firstName,
            lastname: patient.lastName
          }
        }
      }
    )
  }

  doLogout(): void {
    this.navigationService.next()
  }
}
