import { throwError, Observable, of } from 'rxjs'
import { Injectable } from '@angular/core'
import { catchError, first, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { cloneDeep } from 'lodash'

import { environment } from '../../environments/environment'
import { API_PATH } from '../config/config'

// @FIXME: import demo files directly to mock the API while in development and demo
import * as questionnairesDemo from '../../../api-mock/api/v1/static/questionnaires-demo-201809.json'
import * as questionnairesHabVie from '../../../api-mock/api/v1/static/questionnaires-grepec.json'
const codesMap = {
  123456: questionnairesDemo,
  456789: questionnairesHabVie
}

@Injectable()
export class TaskLoadService {
  taskUrl = environment.apiUrl + API_PATH.questionnaires

  constructor(private http: HttpClient) {}

  load(code: any): Observable<any> {
    return this.http.get(this.taskUrl + code).pipe(
      first(),
      map(response => response),
      catchError((error: any) => {
        // if API unavaible, return static file: mostly useful for test and offline mode
        console.log('API server unavailable, loading static file.')
        return codesMap[code]
          ? of(cloneDeep(codesMap[code]))
          : throwError(new Error(error.status))
      })
    )
  }
}
