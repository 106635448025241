<ul class="list-bare">
  <li class="list-bare__item" *ngFor="let item of response.values">
    <img src="{{ item.value }}" alt="{{ item.id }}" [ngClass]="{'is-active': answer?.values.hasOwnProperty(item.id)}"
      (click)="save(item.id)">
  </li>
</ul>

<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
