import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'

import { QuestionChild } from '../question.component'
import { Question } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-question-graphic-exclusive',
  templateUrl: './question-graphic-exclusive.component.html',
  styleUrls: ['./question-graphic-exclusive.component.scss']
})
export class QuestionGraphicExclusiveComponent
  implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  readonly answerSelection: Subject<object> = new Subject()

  ngOnInit(): void {
    this.answer = this.question.answer
  }

  save(id: any): void {
    this.answer = {
      values: { [id]: { id, value: id } },
      status: AnswerStatus.Answered
    }
    this.answerSelection.next(this.answer)
  }
}
