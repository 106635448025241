<div class="capto-app" [ngClass]="{'capto-child-page': childPage}" lang="{{ translate.currentLang }}">

  <main class="main">
    <div class="container">

      <router-outlet></router-outlet>

    </div>
  </main>
</div>

<!-- THIS IS DEBUG CODE!!! -->
<div class="container" style="position:relative;">
  <div style="position:absolute; right:100%; bottom:10px; margin-right:10px;">
    <ng-container *ngFor="let lang of translate.getLangs()">
      <button style="border:0; padding:0; background-color:transparent; opacity:.25;" (click)="translate.use(lang)"
        *ngIf="lang !== translate.currentLang">
        {{ lang | uppercase }}
      </button>
    </ng-container>
  </div>
</div>
