<div class="pagenav" id="pagenav">
  <div class="pagenav__left">
    <!-- pause button -->
    <button mat-button class="button--round button--outline pagenav__pause" *ngIf="pause" (click)="doPauseApp()">
      <span class="button__label button__label--icon button__label--sm">{{ 'app.pause' | translate | uppercase }}</span>
    </button>
    <!-- prev button -->
    <button mat-button class="button--round pagenav__prev" *ngIf="prev" [disabled]="_prev.disabled" [attr.aria-label]="_prev.label ? _prev.label : 'app.prev' | translate"
      (click)="doPrevPage()">
      <span class="button__label button__label--icon">
        <mat-icon>navigate_before</mat-icon>
      </span><span class="button__label button__label--text button__label--text-right" [ngClass]="{'button__label--show': _prev.showLabel}"
        *ngIf="_prev.label">
        {{ _prev.label }}
      </span>
    </button>
  </div>

  <div class="pagenav__right">
    <!-- next button -->
    <button mat-button class="button--round pagenav__next" *ngIf="next" [disabled]="_next.disabled" [attr.aria-label]="_next.label ? _next.label : 'app.next' | translate"
      (click)="doNextPage()">
      <span class="button__label button__label--text button__label--text-left" [ngClass]="{'button__label--show': _next.showLabel}"
        *ngIf="_next.label">
        {{ _next.label }}
      </span><span class="button__label button__label--icon">
        <mat-icon>navigate_next</mat-icon>
      </span>
    </button>
  </div>
</div>
