<ul class="list-bare" [ngClass]="{'list-bare--inline': listInline()}">
  <li class="list-bare__item" *ngFor="let item of response.values">
    <button mat-button class="button--rect" [ngClass]="{'is-active': answer?.values.hasOwnProperty(item.id)}" (click)="save(item.id)">{{
      item.title }}</button>

    <ng-container *ngFor="let sq of question.subquestions">
      <div *ngIf="sq.isVisibleInline(item.id, answer)" class="subquestion subquestion--inline">
        <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
      </div>
    </ng-container>

  </li>
</ul>

<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
