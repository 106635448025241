import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { first } from 'rxjs/operators'

import { AppState } from '../store'
import { selectCode } from '../store/selector/router.selector'
import { selectPrev, selectNext } from '../store/selector/destination.selector'
import * as UserActions from '../store/user.actions'
import * as DestinationActions from '../store/destination.actions'
import { routeSegment } from '../models/routerState'
import { FormId } from '../models/form'
import { QuestionId } from '../models/question'

@Injectable()
export class NavigationService {
  constructor(private store: Store<AppState>, private router: Router) {}

  // called from app.component
  navigate(url: string[], skip = false): void {
    this.router.navigate(url, { skipLocationChange: skip })
  }

  pause(): void {
    // TODO: implement real pause
    // temporary for demo: go to forms review
    this.store
      .pipe(
        first(),
        select(selectCode)
      )
      .subscribe(code => {
        this.store.dispatch(
          new DestinationActions.Set([code, routeSegment.questionnaires])
        )
      })
  }

  prev(): void {
    this.store
      .pipe(
        first(),
        select(selectPrev)
      )
      .subscribe(prev => {
        if (prev.length === 0) {
          this.store.dispatch(new UserActions.UserLogout())
          this.navigate([''])
        } else {
          this.store.dispatch(new DestinationActions.Set(prev))
        }
      })
  }

  next(): void {
    this.store
      .pipe(
        first(),
        select(selectNext)
      )
      .subscribe(next => {
        if (next.length === 0) {
          this.store.dispatch(new UserActions.UserLogout())
          this.navigate([''])
        } else {
          this.store.dispatch(new DestinationActions.Set(next))
        }
      })
  }

  goToQuestion(formId: FormId, questionId: QuestionId): void {
    this.store
      .pipe(
        first(),
        select(selectCode)
      )
      .subscribe(code => {
        this.store.dispatch(
          new DestinationActions.Set([
            code,
            routeSegment.questionnaire,
            formId,
            routeSegment.question,
            questionId
          ])
        )
      })
  }
}
