import { Action } from '@ngrx/store'

export const LOAD_FORMS = 'LoadForms'

export class LoadForms implements Action {
  readonly type = LOAD_FORMS
  constructor(public payload) {}
}

export type All = LoadForms
