import { createSelector } from '@ngrx/store'
import {
  cloneDeep,
  find,
  findIndex,
  intersection,
  isEmpty,
  isUndefined,
  last,
  reduce
} from 'lodash'

import { Form, FormLocalizables } from '../../models/form'
import { Localizer } from '../../utils/localizer'
import {
  getAnswersState,
  getFormsState,
  getFormsStateState,
  getLocaleState,
  getRouterState
} from './state.selector'

export const selectForms = createSelector(
  getFormsState,
  getLocaleState,
  (forms, locale): Form[] => {
    if (isUndefined(forms)) {
      return undefined
    }

    const localizer = new Localizer(locale)
    const selectedForms: Form[] = cloneDeep(forms)

    // map localizable to current locale
    selectedForms.forEach(form =>
      localizer.setLocalizable(form, FormLocalizables)
    )

    return selectedForms
  }
)

export const selectForm = createSelector(
  selectForms,
  (forms, props): Form => {
    if (isUndefined(props) || isUndefined(props.id)) {
      return undefined
    }
    return find(forms, form => form.id === props.id)
  }
)

export const selectLastForm = createSelector(selectForms, last)

export const selectFormPosition = createSelector(
  selectForms,
  (forms, props): number => {
    if (isUndefined(props) || isUndefined(props.id)) {
      return undefined
    }
    const idx = findIndex(forms, (form: Form) => form.id === props.id)
    return idx > -1 ? idx + 1 : undefined
  }
)

export const selectCompletedFormsCount = createSelector(
  getFormsStateState,
  (formsState): number => {
    if (isUndefined(formsState) || isUndefined(formsState.forms)) {
      return 0
    }
    return reduce(
      formsState.forms,
      (count, form) => form.completed ? count + 1 : count,
      0
    )
  }
)

export const isFormComplete = createSelector(
  getRouterState,
  selectForms,
  getAnswersState,
  (router, forms, answers, props): boolean => {
    let completed = false
    if (isUndefined(forms) || isUndefined(answers)) {
      return completed
    }
    const formId = !isEmpty(props) ? props.formId : router.state.params.formId
    const form = forms.find(f => f.id === formId)

    if (form && !isUndefined(answers[form.id])) {
      const questionIds = form.questions.map(q => q.id)
      const answered = intersection(questionIds, Object.keys(answers[form.id]))
      completed = questionIds.length === answered.length
    }

    return completed
  }
)
