import * as PatientActions from './patient.actions'
import { Patient } from '../models/patient'

export type Action = PatientActions.All

export function patientReducer(state: Patient = null, action: Action): Patient {
  switch (action.type) {
    case PatientActions.LOAD_PATIENT:
      return action.payload
    default:
      return state
  }
}
