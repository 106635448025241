import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

@Injectable()
export class AuthGuard implements CanActivate {
  canActivate(): boolean {
    // TODO: implement real guard feature
    console.log('Mock AuthGuard#canActivate called')
    return true
  }
}
