import { Action } from '@ngrx/store'

export const SET_LOCALE = 'SetLocale'

export class SetLocale implements Action {
  readonly type = SET_LOCALE
  constructor(public payload) {}
}

export type All = SetLocale
