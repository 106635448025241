import { FormModel } from './formModel'
import { Answer, FormAnswers } from './answer'
import { QuestionCategoryId } from './questionCategory'
import { Localizable } from './localizable'

export type QuestionId = string
export type ItemId = string
export type CriterionId = string

// fields that are localized, wherever in the model: see json schema
export const QuestionLocalizables: Localizable[] = [
  { path: null, field: 'title' },
  { path: null, field: 'subtitle' },
  { path: null, field: 'hint' },
  { path: null, field: 'content' },
  { path: 'items', field: 'title' },
  { path: 'responseSet.values', field: 'title' }
]

export enum QuestionType {
  GraphicExclusive = 'GRAPHIC_EXCLUSIVE',
  GraphicMultiple = 'GRAPHIC_MULTIPLE',
  Level = 'LEVEL',
  LevelVertical = 'LEVEL_VERTICAL',
  LevelNamed = 'LEVEL_NAMED',
  ListExclusive = 'LIST_EXCLUSIVE',
  ListMultiple = 'LIST_MULTIPLE',
  Number = 'NUMBER',
  Text = 'TEXT',
  YesNo = 'YES_NO'
}

export enum QuestionSubtype {
  Checkbox = 'CHECKBOX'
}

export interface QuestionLevelOptions {
  readonly allEqual?: boolean
}

export interface QuestionListOptions {
  readonly inline?: boolean
}

export interface QuestionNumberOptions {
  readonly min?: number
  readonly max?: number
}

export interface QuestionOptions {
  readonly level?: QuestionLevelOptions
  readonly list?: QuestionListOptions
  readonly number?: QuestionNumberOptions
}

export interface Item {
  readonly id: ItemId
  readonly title: object | string
}

export interface Criterion {
  readonly id: CriterionId
  readonly pattern?: string
  readonly value?: any
}

export interface Condition {
  readonly criteria?: Criterion[]
  readonly inline?: boolean
}

export interface Question {
  readonly id: QuestionId
  readonly type: QuestionType
  readonly subtype?: QuestionSubtype
  readonly options?: QuestionOptions
  readonly mandatory: boolean
  readonly categoryId?: QuestionCategoryId
  readonly title: object | string
  readonly subtitle?: object | string
  readonly hint?: object | string
  readonly content?: object | string
  readonly responseSet?: any
  readonly items?: Item[]
  readonly subquestions?: Question[]
  readonly condition?: Condition // only exist on subquestion
  itemNumber?: number
  answer?: Answer
}

export interface PageQuestion {
  readonly form: FormModel
  readonly question: Question
  readonly answers: FormAnswers
  readonly isLastQuestion: boolean
}
