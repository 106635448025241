<div class="thermometer">
  <div class="thermometer__tube"></div>
  <div class="thermometer__scale">
    <div *ngFor="let level of range; let first = first; let last = last">
      <button mat-button class="button--round button--small button--transparent" [ngClass]="{'is-active': answer?.values[question.id]?.value === level}"
        (click)="save(level)">{{ level }}</button>
      <span *ngIf="lowLevel.value === level" class="thermometer__label thermometer__label--lo">
        {{ lowLevel.title }}
      </span>
      <span *ngIf="highLevel.value === level" class="thermometer__label thermometer__label--hi">
        {{ highLevel.title }}
      </span>
    </div>
  </div>
</div>


<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
