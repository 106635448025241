import { get, has, isArray, isEmpty, isObject } from 'lodash'

import { Localizable } from '../models/localizable'

export class Localizer {
  locale: string

  constructor(locale: string) {
    this.locale = locale
  }

  // replace locales object by the string of the provided locale
  setLocalizable<T extends object>(
    source: T,
    sourceLocalizables: Localizable[]
  ): T {
    const getFieldLocale = field =>
      isObject(field) && has(field, this.locale)
        ? field[this.locale]
        : isEmpty(field)
          ? null
          : field

    sourceLocalizables.forEach(({ path, field }) => {
      const parentSource = get(source, path, source)
      if (isArray(parentSource)) {
        parentSource.forEach(item => {
          if (item[field]) {
            item[field] = getFieldLocale(item[field])
          }
        })
      } else if (isObject(parentSource)) {
        parentSource[field] = getFieldLocale(parentSource[field])
      }
    })
    return source
  }
}
