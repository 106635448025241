import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router'

import { TaskService } from './task.service'
import { AppState } from '../store'
import * as DestinationActions from '../store/destination.actions'
import { routeSegment } from '../models/routerState'

@Injectable()
export class TaskResolver implements Resolve<object> {
  constructor(
    private store: Store<AppState>,
    private taskService: TaskService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): object {
    let code = route.paramMap.get('code')

    // if code length > 6, we presume the code is a hash coming from a (email) link
    // TODO: implement real hashed code validation/processing (maybe use https://github.com/danang-id/simple-crypto-js)
    if (code.length > 6) {
      code = code.slice(-6)
    }

    // This resolver is used to simplify route access during development
    // When going through a normal user flow, the task is already loaded through the passcode
    // If we access/reload a route directly, loadtasks will be called
    return this.taskService
      .getTask()
      .pipe(first())
      .subscribe(currentTask => {
        if (currentTask) {
          return currentTask
        } else {
          return this.taskService
            .loadTask(code)
            .pipe(first())
            .subscribe(task => {
              if (task) {
                this.store.dispatch(
                  new DestinationActions.Set([
                    code,
                    routeSegment.questionnaires
                  ])
                )
                return task
              } else {
                // id not found
                this.store.dispatch(new DestinationActions.Set(['']))
                return null
              }
            })
        }
      })
  }
}
