import { Component, HostBinding, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { forkJoin } from 'rxjs'
import { switchMap, first } from 'rxjs/operators'
import { isUndefined } from 'lodash'

import { TaskService } from '../../../services/task.service'
import { LocaleService } from '../../../services/locale.service'
import { routerTransition } from '../../../animation/router.animation'
import { Form, FormType } from '../../../models/form'
import { FormState } from '../../../models/formsState'

@Component({
  selector: 'capto-page-form-start',
  templateUrl: './page-form-start.component.html',
  styleUrls: ['./page-form-start.component.scss'],
  animations: [routerTransition()]
})
export class PageFormStartComponent implements OnInit {
  @HostBinding('@routerTransition')
  true

  form: Form
  formType = FormType
  formState: FormState
  titleParam = {}

  constructor(
    private taskService: TaskService,
    protected translate: LocaleService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.taskService.getForm(params.get('formId'))
        ),
        first()
      )
      .subscribe(form => {
        if (!isUndefined(form)) {
          this.form = form
          this.taskService
            .getFormsState()
            .pipe(first())
            .subscribe(formsState => {
              this.formState = formsState.forms[form.id]
            })
          const formPosition$ = this.taskService
            .getFormPosition(form.id)
            .pipe(first())
          const taskPatient$ = this.taskService.getPatient().pipe(first())
          const combinedObs = forkJoin(formPosition$, taskPatient$)
          combinedObs.subscribe(([formPosition, taskPatient]) => {
            const nth = formPosition < 4 ? formPosition : 'other'
            this.translate
              .get(`app.nth.${nth.toString()}`)
              .pipe(first())
              .subscribe(res => {
                this.titleParam = {
                  firstname: taskPatient.firstName,
                  lastname: taskPatient.lastName,
                  formNumber: formPosition,
                  formNth: res
                }
              })
          })
        }
      })
  }
}
