import { Component, HostBinding } from '@angular/core'

@Component({
  selector: 'capto-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: []
})
export class PageNotFoundComponent {
  @HostBinding('class.page-wrapper')
  true
}
