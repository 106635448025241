import { createFeatureSelector } from '@ngrx/store'
import { RouterReducerState } from '@ngrx/router-store'

import { Destination } from '../../models/destination'
import { Task } from '../../models/task'
import { Patient } from '../../models/patient'
import { Form } from '../../models/form'
import { FormsState } from '../../models/formsState'
import { FormAnswers } from '../../models/answer'
import { RouterState } from '../../models/routerState'

export const getLocaleState = createFeatureSelector<string>('locale')
export const getDestinationState = createFeatureSelector<Destination>(
  'destination'
)
export const getTaskState = createFeatureSelector<Task>('task')
export const getPatientState = createFeatureSelector<Patient>('patient')
export const getFormsState = createFeatureSelector<Form[]>('forms')
export const getFormsStateState = createFeatureSelector<FormsState>(
  'formsState'
)
export const getAnswersState = createFeatureSelector<FormAnswers>('answers')
export const getRouterState = createFeatureSelector<
  RouterReducerState<RouterState>
>('router')
