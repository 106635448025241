import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store'
import { storeFreeze } from 'ngrx-store-freeze'
import { RouterStateSnapshot } from '@angular/router'
import {
  routerReducer,
  RouterReducerState,
  RouterStateSerializer
} from '@ngrx/router-store'

import { localeReducer } from './locale.reducer'
import { destinationReducer } from './destination.reducer'
import { taskReducer } from './task.reducer'
import { patientReducer } from './patient.reducer'
import { formReducer } from './form.reducer'
import { formsStateReducer } from './formsState.reducer'
import { answerReducer } from './answer.reducer'

import * as UserActions from './user.actions'

import { Destination } from '../models/destination'
import { Task } from '../models/task'
import { Patient } from '../models/patient'
import { Form } from '../models/form'
import { FormsState } from '../models/formsState'
import { RouterState } from '../models/routerState'
import { FormAnswers } from '../models/answer'

export interface AppState {
  locale: string
  destination: Destination
  task: Task
  patient: Patient
  forms: Form[]
  formsState: FormsState
  answers: FormAnswers
  router: RouterReducerState<RouterState>
}

export const reducers: ActionReducerMap<AppState> = {
  locale: localeReducer,
  destination: destinationReducer,
  task: taskReducer,
  patient: patientReducer,
  forms: formReducer,
  formsState: formsStateReducer,
  answers: answerReducer,
  router: routerReducer
}

export class RouterSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root

    while (route.firstChild) {
      route = route.firstChild
    }

    const {
      url,
      root: { queryParams }
    } = routerState
    const { params } = route

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams }
  }
}

export function storeStateGuard(reducer: any): any {
  return (state, action) => {
    if (action.type === UserActions.USER_LOGOUT) {
      return reducer(StoreModule.forRoot(reducers), action) // reset state
    }
    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer<any, any>[] = [
  storeStateGuard,
  // !environment.production ? storeFreeze : null
]
