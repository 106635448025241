import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { mapValues } from 'lodash'
import { first, map } from 'rxjs/operators'

import { AppState } from '../store'
import { selectAnswers } from '../store/selector/answer.selector'
import {
  selectForms,
  selectForm,
  selectLastForm,
  selectFormPosition,
  selectCompletedFormsCount
} from '../store/selector/forms.selector'
import { Task } from '../models/task'
import { Patient } from '../models/patient'
import { Form, FormId } from '../models/form'
import { FormModel } from '../models/formModel'
import { FormsState } from '../models/formsState'
import { TaskLoadService } from './task-load.service'
import * as TaskActions from '../store/task.actions'
import * as PatientActions from '../store/patient.actions'
import * as FormActions from '../store/form.actions'
import * as FormsStateActions from '../store/formsState.actions'

@Injectable()
export class TaskService {
  constructor(
    private store: Store<AppState>,
    private taskLoadService: TaskLoadService
  ) {}

  loadTask(code: any): Observable<any> {
    return this.taskLoadService.load(code).pipe(
      first(),
      map(
        task => {
          const taskData = {
            id: task.id,
            uuid: task.uuid,
            status: task.status,
            version: task.version
          }
          const forms = []
          mapValues(task.questionnaires, questionnaire => {
            forms.push(new FormModel(questionnaire))
          })

          this.store.dispatch(new TaskActions.LoadTask(taskData))
          this.store.dispatch(new PatientActions.LoadPatient(task.patient))
          this.store.dispatch(new FormActions.LoadForms(forms))
          this.store.dispatch(new FormsStateActions.InitFormsState(forms))

          return taskData
        },
        error => error
      )
    )
  }

  getTask(): Observable<Task> {
    return this.store.select('task')
  }

  getPatient(): Observable<Patient> {
    return this.store.select('patient')
  }

  getForms(): Observable<Form[]> {
    return this.store.pipe(select(selectForms))
  }

  getFormsState(): Observable<FormsState> {
    return this.store.select('formsState')
  }

  getAnswers(): Observable<object> {
    return this.store.pipe(select(selectAnswers))
  }

  getForm(id: FormId): Observable<Form> {
    return this.store.pipe(select(selectForm, { id }))
  }

  getLastForm(): Observable<Form> {
    return this.store.pipe(select(selectLastForm))
  }

  getFormPosition(id: FormId): Observable<number> {
    return this.store.pipe(select(selectFormPosition))
  }

  getCompletedFormsCount(): Observable<number> {
    return this.store.pipe(select(selectCompletedFormsCount))
  }

  setFormCompleted(formId: FormId): void {
    this.store.dispatch(new FormsStateActions.SetCompleted(formId))
  }
}
