import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { API_PATH } from '../config/config'
import { Answer } from '../models/answer'

@Injectable()
export class AnswerCommitService {
  answerUrl = environment.apiUrl + API_PATH.reponse

  constructor(private http: HttpClient) {}

  create(payload: any): Promise<Answer> {
    // @FIXME: enable when ready to implement real API
    // return this.http
    //   .post(this.answerUrl, JSON.stringify(payload))
    //   .toPromise()
    //   .then(res => res['data'] as Answer)
    //   .catch(this.handleError)
    return payload
  }

  // private handleError(error: any): Promise<any> {
  //   console.error('An error occurred', error) // for demo purposes only
  //   return Promise.reject(error.message || error)
  // }
}
