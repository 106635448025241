<ul class="list-bare">
  <li class="list-bare__item" *ngFor="let item of question.items">

    <div class="question">
      <div class="question__title" *ngIf="question?.subtype && question?.subtype === questionSubtype.Checkbox; else regular_yes_no">
        <mat-checkbox class="mat-checkbox--large" [checked]="(answer?.values.hasOwnProperty(item.id) && answer?.values[item.id].value)"
          (change)="updateChecked(item.id, $event)">
          {{ item.title }}
        </mat-checkbox>
      </div>

      <ng-template #regular_yes_no>
        <div class="question__title">
          {{ item.title }}
        </div>

        <div class="question__answer">
          <button mat-button class="button--rect u-margin-left" [ngClass]="{ 'is-active': (answer?.values.hasOwnProperty(item.id)
            && answer?.values[item.id].value === 1 )}"
            (click)="save(item.id, 1)">
            {{ 'app.yes' | translate }}
          </button>

          <button mat-button class="button--rect u-margin-left" [ngClass]="{ 'is-active': (answer?.values.hasOwnProperty(item.id)
            && answer?.values[item.id].value === 0 )}"
            (click)="save(item.id, 0)">
            {{ 'app.no' | translate }}
          </button>
        </div>
      </ng-template>
    </div>

    <ng-container *ngFor="let sq of question.subquestions">
      <div *ngIf="sq.isVisibleInline(item.id, answer)" class="subquestion subquestion--inline">
        <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
      </div>
    </ng-container>

  </li>
</ul>

<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
