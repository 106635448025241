import { Component, Input } from '@angular/core'

@Component({
  selector: 'capto-form-icon',
  templateUrl: './form-icon.component.html',
  styleUrls: ['./form-icon.component.scss']
})
export class FormIconComponent {
  @Input()
  code: string
  @Input()
  consent = false
  @Input()
  completed = false
}
