import { QuestionCategory } from './questionCategory'
import { Question } from './question'
import { Response } from './response'
import { Localizable } from './localizable'

export type FormId = string

export const FormLocalizables: Localizable[] = [
  { path: null, field: 'title' },
  { path: null, field: 'shortname' },
  { path: null, field: 'description' },
  { path: 'categories', field: 'title' }
]

export enum FormType {
  Consent = 'CONSENT',
  Medical = 'MEDICAL'
}

export enum FormStatus {
  Planned = 'PLANNED'
}

export interface Form {
  readonly id: FormId
  readonly type: FormType
  readonly status: FormStatus
  readonly shortname: object | string
  readonly title: object | string
  readonly description: object | string
  readonly categories: QuestionCategory[]
  readonly questions: Question[]
  readonly responses: Response[]
}
