import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { cloneDeep, set } from 'lodash'

import { QuestionChild } from '../question.component'
import { Question, QuestionSubtype } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-question-yes-no',
  templateUrl: './question-yes-no.component.html',
  styleUrls: ['./question-yes-no.component.scss']
})
export class QuestionYesNoComponent implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  answerSelection: Subject<object> = new Subject()
  questionSubtype = QuestionSubtype

  ngOnInit(): void {
    this.answer = this.question.answer
  }

  updateChecked(id, event): void {
    this.save(id, event.checked ? 1 : 0)
  }

  save(id: any, value: number): void {
    // use local var because we can’t mutate state
    const answer = cloneDeep(this.answer) || {}

    set(answer, ['values', id], { id, value })

    this.answer = answer
    this.answer.status = AnswerStatus.Answered

    this.answerSelection.next(answer)
  }
}
