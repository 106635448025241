import { createSelector } from '@ngrx/store'
import { cloneDeep, get, isUndefined, map, last } from 'lodash'

import { Localizer } from '../../utils/localizer'
import {
  getRouterState,
  getAnswersState,
  getLocaleState
} from './state.selector'
import { selectForms } from './forms.selector'
import {
  Question,
  QuestionLocalizables,
  PageQuestion
} from '../../models/question'

export const selectQuestion = createSelector(
  getRouterState,
  selectForms,
  getAnswersState,
  getLocaleState,
  (router, forms, answers, locale, props): PageQuestion => {
    if (isUndefined(router) && isUndefined(forms)) {
      return undefined
    }
    const formId = !isUndefined(props)
      ? props.formId
      : router.state.params.formId
    const questionId = !isUndefined(props)
      ? props.questionId
      : router.state.params.questionId

    const form = forms.find(f => f.id === formId)
    if (!form) {
      return undefined
    }
    const question: Question = form.questions.find(q => {
      if (questionId) {
        return q.id === questionId
      }
    })

    if (!question) {
      return undefined
    }

    // recursively set answer and localizable on question and subquestions
    const localizer = new Localizer(locale)
    function cloneQuestion(q: Question): Question {
      const clonedQ = localizer.setLocalizable(
        cloneDeep(q),
        QuestionLocalizables
      )
      const answer = get(answers, [formId, q.id])
      const subquestions = map(q.subquestions, cloneQuestion)
      return Object.assign(clonedQ, { answer, subquestions })
    }

    // set last question
    const lastQuestion: Question = last(form.questions)
    const isLastQuestion = lastQuestion.id === question.id

    return { form, question: cloneQuestion(question), answers, isLastQuestion }
  }
)
