<div class="page page--vcenter">
  <div class="content">

    <ul class="list-inline">
      <li class="list-inline__item" *ngFor="let form of forms">
        <capto-form-icon [consent]="form.type === formType.Consent" [code]="form.shortname" [completed]="formsState && formsState.forms && formsState.forms[form.id].completed"></capto-form-icon>
      </li>
    </ul>

    <h1 [innerHTML]="'app.title.formsCompleted' | translate:titleParam"></h1>

    <div class="u-margin-top-huge">
      <button mat-button class="button--rect" (click)="doLogout()">{{ 'app.action.closeForms' | translate }}</button>
    </div>

  </div>
</div>
