import { Action } from '@ngrx/store'
import { Answer } from '../models/answer'

export const SAVE_ANSWER = 'SaveAnswer'

export class SaveAnswer implements Action {
  readonly type = SAVE_ANSWER
  constructor(public payload: {formId: string, questionId: string, answer: Answer}) {
  }
}

export type All = SaveAnswer
