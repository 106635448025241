<capto-progress [formId]="form?.id" [formCode]="form?.shortname" [categories]="form?.categories" [questions]="form?.questions"
  [answers]="answers" [currentQuestionNumber]="question?.itemNumber" [pageCompleted]="pageCompleted$.value"
  (navigateTo)="onNavigateTo($event)"></capto-progress>

<div class="content">
  <ng-container *ngIf="question">

    <h1 class="question__title">
      <span class="question__number">{{ question?.itemNumber }}.</span>
      <span *ngIf="category?.title" innerHTML="{{ category.title }}" class="question__category"></span>
      <span innerHTML="{{ question?.title }}"></span>
      <button *ngIf="false" mat-mini-fab class="button--round button--small button--dim question__tooltip">?</button>
    </h1>

    <capto-question [formId]="form.id" [question]="question"></capto-question>

  </ng-container>
</div>

<capto-pagenav [pause]="true" [prev]="{disabled: false}" [next]="{label: (isLastQuestion ? 'app.next' : 'app.nextQuestion') | translate, showLabel: false, animateLabel: true, disabled: !pageCompleted$.value && question?.mandatory}"></capto-pagenav>
