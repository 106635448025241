import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core'
import { combineLatest, Subject } from 'rxjs'
import { first, map, takeUntil } from 'rxjs/operators'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { find, isUndefined } from 'lodash'

import { routerTransition } from '../../../animation/router.animation'
import { LocaleService } from '../../../services/locale.service'
import { TaskService } from '../../../services/task.service'
import { Form, FormType } from '../../../models/form'
import { FormsState } from '../../../models/formsState'
import { Patient } from '../../../models/patient'

export const REVIEW_TYPE_INITIAL = 'initial'

@Component({
  selector: 'capto-forms-review',
  templateUrl: './page-forms-review.component.html',
  styleUrls: ['./page-forms-review.component.scss'],
  animations: [routerTransition()]
})
export class PageFormsReviewComponent implements OnInit, OnDestroy {
  @HostBinding('@routerTransition')
  true

  forms: Form[] = []
  formType = FormType
  formsState: FormsState
  currentFormId = null
  reviewType = ''
  titleParam: object = {}
  formsParam: object = {}
  isLastForm = false
  private destroy$ = new Subject()

  get initialType(): string {
    return REVIEW_TYPE_INITIAL
  }

  get consentType(): FormType {
    return FormType.Consent
  }

  get prevLabel(): string {
    let label = ''
    let labelStr = ''
    if (this.reviewType === REVIEW_TYPE_INITIAL) {
      label = 'app.quit'
    }
    if (label) {
      this.translate
        .get(label)
        .pipe(first())
        .subscribe(res => (labelStr = res))
    }
    return labelStr
  }

  get nextLabel(): string {
    let label = 'app.nextForm'
    let labelStr = ''
    if (this.reviewType === REVIEW_TYPE_INITIAL) {
      label = 'app.start'
    } else if (this.isLastForm) {
      label = 'app.lastForm'
    }
    this.translate
      .get(label)
      .pipe(first())
      .subscribe(res => (labelStr = res))
    return labelStr
  }

  constructor(
    public translate: LocaleService,
    private route: ActivatedRoute,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    this.taskService
      .getFormsState()
      .pipe(takeUntil(this.destroy$))
      .subscribe(formsState => {
        this.formsState = formsState
      })

    this.route.data
      .pipe(
        takeUntil(this.destroy$),
        map(data => data.initial || false)
      )
      .subscribe(initial => {
        this.reviewType = initial ? REVIEW_TYPE_INITIAL : FormType.Medical
      })

    const forms$ = this.taskService.getForms().pipe(takeUntil(this.destroy$))
    const currentFormId$ = this.route.paramMap.pipe(
      takeUntil(this.destroy$),
      map((params: ParamMap) => params.get('formId'))
    )
    combineLatest(forms$, currentFormId$).subscribe(
      ([forms, currentFormId]) => {
        this.forms = forms
        this.formsParam = { ...this.formsParam, formsCount: forms.length }
        this.currentFormId = currentFormId
        const currentForm = find(forms, form => {
          if (!isUndefined(currentFormId)) {
            return form.id === currentFormId
          }
          return false
        })
        if (currentForm && this.reviewType !== REVIEW_TYPE_INITIAL) {
          this.reviewType = currentForm.type
        }
        if (!isUndefined(currentForm)) {
          this.taskService
            .getLastForm()
            .pipe(first())
            .subscribe(form => {
              if (!isUndefined(form)) {
                this.isLastForm = form.id === currentForm.id
              }
            })
        }
      }
    )

    this.taskService
      .getCompletedFormsCount()
      .pipe(takeUntil(this.destroy$))
      .subscribe(completedFormsCount => {
        const nth = completedFormsCount < 4 ? completedFormsCount : 'other'
        this.translate
          .get('app.nth.' + nth.toString())
          .pipe(first())
          .subscribe(
            res =>
              (this.formsParam = {
                ...this.formsParam,
                formNth: completedFormsCount + res
              })
          )
      })

    this.taskService
      .getPatient()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (patient: Patient): void => {
          if (patient) {
            this.titleParam = {
              firstname: patient.firstName,
              lastname: patient.lastName
            }
          }
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
  }
}
