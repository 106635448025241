import * as TaskActions from './task.actions'
import { Task } from '../models/task'

export type Action = TaskActions.All

export function taskReducer(state: Task = null, action: Action): Task {
  switch (action.type) {
    case TaskActions.LOAD_TASK:
      return action.payload
    default:
      return state
  }
}
