<div class="u-margin-top">
  <ng-container *ngIf="question?.subtype === 'textarea'; then textareaBlock else inputBlock"></ng-container>
</div>

<ng-template #textareaBlock>
  <mat-form-field>
    <textarea matInput #textarea (blur)="save(textarea.value)" [placeholder]="question?.title" [required]="question?.mandatory">{{ answer?.values[question.id]?.value }}</textarea>
  </mat-form-field>
</ng-template>

<ng-template #inputBlock>
  <mat-form-field>
    <input matInput #input (keyup)="answerValue.next(input.value)" [type]="question?.subtype || 'text'" [placeholder]="question?.title"
      [required]="question?.mandatory" [value]="answer?.values[question.id]?.value">
  </mat-form-field>
</ng-template>


<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
