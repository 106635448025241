import { Action } from '@ngrx/store'

export const LOAD_PATIENT = 'LoadPatient'

export class LoadPatient implements Action {
  readonly type = LOAD_PATIENT
  constructor(public payload) {}
}

export type All = LoadPatient
