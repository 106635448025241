<table class="levels" [ngClass]="{'levels__all-equal': levelAllEqual()}">
  <thead>
    <tr>
      <th></th>
      <th *ngFor="let level of response.values; let idx = index" class="level__label" [ngClass]="'level__label--' + (idx + 1) + 'of' + response.values.length">
        {{ level.title }}
      </th>
    </tr>
    <tr>
      <th></th>
      <th colSpan="{{ response.values.length }}">
        <div class="level__tube"></div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of question.items">
      <th>{{ item.title }}</th>
      <td *ngFor="let level of response.values">
        <button mat-button class="button--round button--outline" [ngClass]="{'is-active': answer?.values[item.id]?.value === level.id}"
          attr.aria-label="{{ level.title }}" (click)="save(item.id, level.id)"></button>
      </td>
    </tr>
  </tbody>
</table>

<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
