<svg width="85px" height="105px" viewBox="0 0 85 105" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g class="form-icon" [ngClass]="{'is-completed': completed, 'is-consent': consent}">
    <path class="form-icon__shape" fill="#8BBF13" d="M7.72727273,0 L66.4545455,0 L85,18.5294118 L85,97.2794118 C85,101.911765 81.9090909,105 77.2727273,105 L7.72727273,105 C3.09090909,105 0,101.911765 0,97.2794118 L0,7.72058824 C0,4.63235294 3.09090909,0 7.72727273,0 L7.72727273,0 Z"></path>
    <polygon class="form-icon__corner" fill="#000000" fill-opacity="0.25" points="66 19 84 19 66 1"></polygon>
    <text class="form-icon__text" fill="#555555" text-anchor="middle" x="42.5" y="58" width="79">
      {{ code }}
    </text>
    <path class="form-icon__check" fill="none" d="M33.7936433,84.0387331 L39.3842817,89.6173858 L40.3416232,90.5726749 L41.1901505,89.5195455 L51.2942876,76.9790365 C51.7156549,76.4560665 51.6332897,75.6905298 51.1103197,75.2691625 C50.5873497,74.8477952 49.821813,74.9301605 49.4004457,75.4531305 L39.2963086,87.9936395 L41.1021774,87.8957992 L35.5115391,82.3171465 C35.0361361,81.8427627 34.2661817,81.8435889 33.7917979,82.3189919 C33.3174141,82.794395 33.3182403,83.5643493 33.7936433,84.0387331 L33.7936433,84.0387331 Z"></path>
    <g class="form-icon__signature" *ngIf="consent && !completed">
      <path fill="#959595" d="M29.776 92.892c.11 1.12.894 2.796 1.418 3.157.506.352 1.419-1.489 1.78-1.873 4.939-5.228 4.965 2.425 12.044 3.4.688.095.604-.441.68-.563.104-.165-3.62-3.07-5.019-3.967-2.584-1.658-1.736-6.136-8.48-1.661 0-.306.199-2.33 0-2.89-.597-1.684-1.357-2.161-2.118-1.958-.05-.198-.1-.396-.17-.597-.2-.538-.479-.807-.792-.919.89-2.568 1.465-5.285.881-7.482-.178-.668-.686-1.01-1.095-1.036-1.693-.105-3.145 2.826-4.234 4.585-2.724 4.403-5.123 9.4-7.444 14.375-.997 2.137 1.576 4.315 2.189 2.114 2.527-9.082 5.128-11.325 7.473-15.325-.565 1.529-2.057 5.671-2.688 7.117-.902 2.3 1.252 4.256 2.188 2.111.223-.56.507-.965.817-1.313l-.188.773c.003 0 .006.003.01.005-.05.167-.1.334-.139.493-.566 2.341 1.55 3.414 2.319 1.608.194-.454.375-.94.538-1.448-.011.35-.016.82.03 1.294z"/>
      <path fill="#555" opacity=".8" d="M62.983 63.131l5.806 3.771-15.023 23.134-5.806-3.771 15.023-23.134zm-1.87 1.608l-5.261 8.102-1.162-.754 5.262-8.102 1.162.754zM47.117 87.564l5.806 3.77-2.61 2.107-1.186.957-4.283 3.456 1.2-5.433.324-1.466.75-3.391z"/>
    </g>
  </g>
</svg>
