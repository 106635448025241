import { LANG_STORAGE_KEY } from './config/config'

// interface Storage {
//   [key: string]: any
// }

import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import {
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import {
  LOCAL_STORAGE,
  StorageService,
  StorageServiceModule
} from 'ngx-webstorage-service'
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import 'hammerjs'
import { Ng5SliderModule } from 'ng5-slider'

// CONFIGS
// import { environment } from '../environments/environment'
import { reducers, metaReducers, RouterSerializer } from './store'

// MODULES
import { AppRoutingModule } from './app-routing.module'

// UI
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxPageScrollModule } from 'ngx-page-scroll'
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule
} from '@angular/material'

// COMPONENTS
import { AppComponent } from './components/app.component'
import { PageHomeComponent } from './components/page/home/page-home.component'
import { PageFormsReviewComponent } from './components/page/forms-review/page-forms-review.component'
import { PageFormStartComponent } from './components/page/form-start/page-form-start.component'
import { PageQuestionComponent } from './components/page/question/page-question.component'
import { PageFormsCompletedComponent } from './components/page/forms-completed/page-forms-completed.component'
import { PageNotFoundComponent } from './components/page/not-found/page-not-found.component'
// feature components
import { QuestionComponent } from './components/question/question.component'
import { FormIconComponent } from './components/form-icon/form-icon.component'
import { PasscodeComponent } from './components/passcode/passcode.component'
import { PagenavComponent } from './components/pagenav/pagenav.component'
import { ProgressComponent } from './components/progress/progress.component'
import { QuestionGraphicExclusiveComponent } from './components/question/graphic-exclusive/question-graphic-exclusive.component'
import { QuestionGraphicMultipleComponent } from './components/question/graphic-multiple/question-graphic-multiple.component'
import { QuestionLevelComponent } from './components/question/level/question-level.component'
import { QuestionLevelNamedComponent } from './components/question/level-named/question-level-named.component'
import { QuestionLevelVerticalComponent } from './components/question/level-vertical/question-level-vertical.component'
import { QuestionListExclusiveComponent } from './components/question/list-exclusive/question-list-exclusive.component'
import { QuestionListMultipleComponent } from './components/question/list-multiple/question-list-multiple.component'
import { QuestionNumberComponent } from './components/question/number/question-number.component'
import { QuestionTextComponent } from './components/question/text/question-text.component'
import { QuestionYesNoComponent } from './components/question/yes-no/question-yes-no.component'

// DIRECTIVES
import { QuestionChildDirective } from './components/question/question-child.directive'

// SERVICES
import { AuthGuard } from './services/auth-guard.service'
import { LocaleService } from './services/locale.service'
import { TaskService } from './services/task.service'
import { TaskLoadService } from './services/task-load.service'
import { NavigationService } from './services/navigation.service'
import { AnswerService } from './services/answer.service'
import { AnswerCommitService } from './services/answer-commit.service'
import { ValidationService } from './services/validation.service'

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http)
}

export function initAppLocale(
  localeService: LocaleService,
  translate: TranslateService,
  storage: StorageService
) {
  return () => {
    translate.addLangs(['en', 'fr'])
    translate.setDefaultLang('fr')
    const lang = storage.get(LANG_STORAGE_KEY) || translate.getBrowserLang()
    const locale = translate.langs.includes(lang)
      ? lang
      : this.translate.defaultLang
    console.log(`Initilazing app locale ${locale}`)
    return localeService.use(locale).toPromise()
  }
}

@NgModule({
  declarations: [
    AppComponent,
    // pages
    PageHomeComponent,
    PageFormsReviewComponent,
    PageFormStartComponent,
    PageQuestionComponent,
    PageFormsCompletedComponent,
    PageNotFoundComponent,
    // feature components
    QuestionComponent,
    FormIconComponent,
    PasscodeComponent,
    PagenavComponent,
    ProgressComponent,
    QuestionGraphicExclusiveComponent,
    QuestionGraphicMultipleComponent,
    QuestionLevelComponent,
    QuestionLevelNamedComponent,
    QuestionLevelVerticalComponent,
    QuestionListExclusiveComponent,
    QuestionListMultipleComponent,
    QuestionNumberComponent,
    QuestionTextComponent,
    QuestionYesNoComponent,
    // directives
    QuestionChildDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule,
    StorageServiceModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25 //  Retains last 25 states
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // UI
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgxPageScrollModule,
    Ng5SliderModule
  ],
  entryComponents: [
    QuestionGraphicExclusiveComponent,
    QuestionGraphicMultipleComponent,
    QuestionLevelComponent,
    QuestionLevelNamedComponent,
    QuestionLevelVerticalComponent,
    QuestionListExclusiveComponent,
    QuestionListMultipleComponent,
    QuestionNumberComponent,
    QuestionTextComponent,
    QuestionYesNoComponent
  ],
  providers: [
    AuthGuard,
    LocaleService,
    TaskService,
    TaskLoadService,
    NavigationService,
    AnswerService,
    AnswerCommitService,
    ValidationService,
    { provide: RouterStateSerializer, useClass: RouterSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppLocale,
      deps: [LocaleService, TranslateService, LOCAL_STORAGE],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
