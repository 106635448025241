import { createSelector } from '@ngrx/store'
import { findIndex, isUndefined, last } from 'lodash'

import {
  getDestinationState,
  getFormsState,
  getRouterState
} from './state.selector'
import { routeSegment } from '../../models/routerState'
import { Destination } from '../../models/destination'
import { Form } from '../../models/form'
import { Question } from '../../models/question'

/**
 * segments[0] = code (6 digits)
 * segments[1] = section : "questionnaires" || "questionnaire" || "fin"
 * segments[2] = id of form
 * segments[3] = form section : "question" || "complete"
 * segments[4] = id of question
 */

export const selectNext = createSelector(
  getDestinationState,
  getFormsState,
  getRouterState,
  (destination, forms, router) => {
    if (isUndefined(destination)) {
      return undefined
    }
    let newDestination = []

    const goToNextForm = (): Destination => {
      const currentFormId = router.state.params.formId || null
      let nextFormId

      if (currentFormId) {
        const currentFormIndex = findIndex(
          forms,
          (form: Form) => form.id === currentFormId
        )
        const nextFormIndex = currentFormIndex + 1
        if (nextFormIndex < forms.length) {
          nextFormId = forms[nextFormIndex].id
        }
      } else {
        nextFormId = forms[0].id
      }

      if (nextFormId) {
        return [destination[0], routeSegment.questionnaire, nextFormId]
      }

      // no more forms, go to end
      return [destination[0], routeSegment.fin]
    }

    const goToNextQuestion = (): Destination => {
      const lastForm = last(forms)
      const currentForm = forms.find(
        form => form.id === router.state.params.formId
      )
      const currentQuestionId = router.state.params.questionId
      let nextQuestionId

      if (currentQuestionId) {
        const currentQuestionIndex = findIndex(
          currentForm.questions,
          (question: Question) => question.id === currentQuestionId
        )
        const nextQuestionIndex = currentQuestionIndex + 1
        if (nextQuestionIndex < currentForm.questions.length) {
          nextQuestionId = currentForm.questions[nextQuestionIndex].id
        }
      } else {
        nextQuestionId = currentForm.questions[0].id
      }

      if (nextQuestionId) {
        return [
          destination[0],
          destination[1],
          destination[2],
          routeSegment.question,
          nextQuestionId
        ]
      }

      // no more question and on last form, go to end
      if (lastForm.id === currentForm.id) {
        return [destination[0], routeSegment.fin]
      }

      // no more question, go to form complete
      return [
        destination[0],
        destination[1],
        destination[2],
        routeSegment.complete
      ]
    }

    switch (destination[1]) {
      case routeSegment.questionnaires:
        newDestination = goToNextForm()
        break
      case routeSegment.questionnaire:
        if (
          !isUndefined(destination[3]) &&
          destination[3] === routeSegment.complete
        ) {
          newDestination = goToNextForm()
        } else {
          newDestination = goToNextQuestion()
        }
        break
    }

    return newDestination
  }
)

export const selectPrev = createSelector(
  getDestinationState,
  getFormsState,
  getRouterState,
  (destination, forms, router) => {
    if (isUndefined(destination)) {
      return undefined
    }
    let newDestination = []

    const goToPrevForm = (): Destination => {
      const currentFormId = router.state.params.formId || null
      const lastForm = last(forms)
      let prevFormId

      if (currentFormId) {
        const currentFormIndex = findIndex(
          forms,
          (form: Form) => form.id === currentFormId
        )
        const prevFormIndex = currentFormIndex - 1
        if (prevFormIndex >= 0) {
          prevFormId = forms[prevFormIndex].id
        }
      } else {
        prevFormId = lastForm.id
      }

      if (prevFormId) {
        return [
          destination[0],
          routeSegment.questionnaire,
          prevFormId,
          routeSegment.complete
        ]
      }

      // no more forms, go to start
      return [destination[0], routeSegment.questionnaires]
    }

    const goToPrevQuestion = (): Destination => {
      const currentForm = forms.find(
        form => form.id === router.state.params.formId
      )
      const currentQuestionId = router.state.params.questionId
      const lastQuestion: Question = last(currentForm.questions)
      let prevQuestionId

      if (currentQuestionId) {
        const currentQuestionIndex = findIndex(
          currentForm.questions,
          (question: Question) => question.id === currentQuestionId
        )
        const prevQuestionIndex = currentQuestionIndex - 1
        if (prevQuestionIndex >= 0) {
          prevQuestionId = currentForm.questions[prevQuestionIndex].id
        }
      } else {
        // at end of form, go to last question
        prevQuestionId = lastQuestion.id
      }

      if (prevQuestionId) {
        return [
          destination[0],
          destination[1],
          destination[2],
          routeSegment.question,
          prevQuestionId
        ]
      }

      // on first question, go to start
      return [destination[0], destination[1], destination[2]]
    }

    switch (destination[1]) {
      case routeSegment.questionnaire:
        if (isUndefined(destination[3])) {
          // go to previous form
          newDestination = goToPrevForm()
        } else {
          newDestination = goToPrevQuestion()
        }
        break
      case routeSegment.fin:
        newDestination = goToPrevForm()
        break
    }

    return newDestination
  }
)
