import { cloneDeep, set } from 'lodash'

import * as AnswerActions from './answer.actions'
import { FormAnswers } from '../models/answer'

export type Action = AnswerActions.All

export function answerReducer(
  state: FormAnswers = {},
  action: Action
): FormAnswers {
  switch (action.type) {
    case AnswerActions.SAVE_ANSWER:
      const newState = cloneDeep(state) || {}
      set(
        newState,
        [action.payload.formId, action.payload.questionId],
        action.payload.answer
      )
      return newState

    default:
      return state
  }
}
