export type AnswerId = string

export enum AnswerStatus {
  Answered = 'ANSWERED',
  Skipped = 'SKIPPED'
}

export interface FormAnswers {
  [key: string]: {
    [key: string]: Answer;
  }
}

export interface AnswerValues {
  id: AnswerId
  value?: any
}

export interface Answer {
  status?: AnswerStatus
  values?: {
    [key: string]: AnswerValues;
  }
}
