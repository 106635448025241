import { createSelector } from '@ngrx/store'

import { getRouterState } from './state.selector'
import { isUndefined } from 'lodash'
import { RouteParams } from '../../models/routerState'

export const selectRouteParams = createSelector(
  getRouterState,
  (router): RouteParams => {
    if (!isUndefined(router)) {
      return {
        code: router.state.params.code || undefined,
        formId: router.state.params.formId || undefined,
        questionId: router.state.params.questionId || undefined
      }
    }
  }
)

export const selectCode = createSelector(
  getRouterState,
  (router): string => {
    if (!isUndefined(router)) {
      return router.state.params.code || undefined
    }
  }
)

export const selectFormId = createSelector(
  getRouterState,
  (router): string => {
    if (!isUndefined(router)) {
      return router.state.params.formId || undefined
    }
  }
)

export const selectQuestionId = createSelector(
  getRouterState,
  (router): string => {
    if (!isUndefined(router)) {
      return router.state.params.questionId || undefined
    }
  }
)
