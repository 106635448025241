<table class="levels" [ngClass]="{'levels__all-equal': levelAllEqual()}">
  <thead>
    <tr>
      <th></th>
      <td [colSpan]="range.length">
        <div class="level__labels">
          <span class="level__label level__label--lo">
            {{ lowLevel.title }}
          </span>
          <span class="level__label level__label--hi">
            {{ highLevel.title }}
          </span>
        </div>
        <div class="level__tube"></div>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of question.items">
      <th>{{ item.title }}</th>
      <td *ngFor="let level of range">
        <button mat-button class="button--round button--transparent" [ngClass]="{'is-active': answer?.values[item.id]?.value === level}"
          (click)="save(item.id, level)">{{ level }}</button>
      </td>
    </tr>
  </tbody>
</table>

<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
