<div class="content">

  <ng-container [ngSwitch]="reviewType">

    <ng-template [ngSwitchCase]="initialType">
      <h1 [innerHTML]="'app.title.greetingUser' | translate:titleParam" class="greeting"></h1>
      <h1 [innerHTML]="'app.title.formsReview' | translate"></h1>
    </ng-template>

    <ng-template [ngSwitchCase]="consentType">
      <h1 [innerHTML]="'app.title.thanksUser' | translate:titleParam"></h1>
      <h1 [innerHTML]="'app.title.formsReviewConsent' | translate"></h1>
    </ng-template>

    <ng-template ngSwitchDefault>
      <h1 [innerHTML]="'app.title.thanksUser' | translate:titleParam"></h1>
      <h1 [innerHTML]="'app.title.formsReviewProgress' | translate:formsParam"></h1>
    </ng-template>

  </ng-container>

  <ul class="list-inline">
    <li class="list-inline__item" *ngFor="let form of forms">
      <capto-form-icon [ngClass]="{'is-active': form.id === currentFormId}" [consent]="form.type === formType.Consent"
        [code]="form.shortname" [completed]="formsState && formsState.forms[form.id].completed"></capto-form-icon>
    </li>
  </ul>

  <div class="u-margin-top-huge" *ngIf="reviewType === initialType">
    <button mat-button class="button--rect" (click)="translate.toggle()">{{ 'app.action.toggleLocale' | translate }}</button>
  </div>

  <capto-pagenav [prev]="{label: prevLabel, showLabel: true, disabled: false}" [next]="{label: nextLabel | translate, showLabel: true, disabled: false}"></capto-pagenav>
</div>
