import { Component, Input } from '@angular/core'

import { NavigationService } from '../../services/navigation.service'

const buttonDefault = {
  label: '',
  showLabel: false,
  animateLabel: false, // if showLabel is false and animatelabel is true, reveal label on hover or when enabling
  disabled: true
}

@Component({
  selector: 'capto-pagenav',
  templateUrl: './pagenav.component.html',
  styleUrls: ['./pagenav.component.scss']
})
export class PagenavComponent {
  private _prev = null
  private _next = null

  @Input()
  pause = false

  @Input()
  set prev(prev: object) {
    this._prev = { ...buttonDefault, ...prev }
  }
  get prev(): object {
    return this._prev
  }

  @Input()
  set next(next: object) {
    this._next = { ...buttonDefault, ...next }
  }
  get next(): object {
    return this._next
  }

  constructor(private navigationService: NavigationService) {}

  doPauseApp(): void {
    this.navigationService.pause()
  }
  doPrevPage(): void {
    this.navigationService.prev()
  }
  doNextPage(): void {
    this.navigationService.next()
  }
}
