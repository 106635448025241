import { Action } from '@ngrx/store'

export const INIT_FORMS_STATE = 'InitFormsState'
export const SET_COMPLETED = 'SetCompleted'

export class InitFormsState implements Action {
  readonly type = INIT_FORMS_STATE
  constructor(public payload) {}
}

export class SetCompleted implements Action {
  readonly type = SET_COMPLETED
  constructor(public payload) {}
}

export type All = InitFormsState | SetCompleted
