import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { AppState } from '../store'
import * as AnswerActions from '../store/answer.actions'
import { AnswerCommitService } from './answer-commit.service'
import { Answer } from '../models/answer'

@Injectable()
export class AnswerService {
  constructor(
    private store: Store<AppState>,
    private answerCommit: AnswerCommitService
  ) {}

  saveAnswer(formId: string, questionId: string, answer: Answer): void {
    const payload = { formId, questionId, answer }

    // persist to store
    this.store.dispatch(new AnswerActions.SaveAnswer(payload))

    // push to web service
    this.answerCommit.create(payload)
    // TODO: deal with error on answer commit
  }
}
