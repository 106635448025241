import {
  Component,
  OnChanges,
  Input,
  ComponentFactoryResolver,
  ViewChild
} from '@angular/core'
import { Subject } from 'rxjs'

import { AnswerService } from '../../services/answer.service'
import { QuestionChildDirective } from './question-child.directive'
import { QuestionFactory } from './question.factory'
import { Question, QuestionType } from '../../models/question'
import { QuestionItem } from '../../models/questionItem'
import { Response } from '../../models/response'
import { Answer } from '../../models/answer'

export interface QuestionChild {
  formId: string
  question: Question
  response: Response
  answer: Answer
  answerSelection: Subject<object> // will be used to broadcast user selection(s)
}

@Component({
  selector: 'capto-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnChanges {
  @ViewChild(QuestionChildDirective)
  captoChildQuestion: QuestionChildDirective

  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  showTitle = false

  questionTypes = QuestionType

  constructor(
    private answerService: AnswerService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges(): void {
    this.loadComponent()
  }

  loadComponent(): void {
    const component = QuestionFactory.createQuestion(this.question.type)
    const viewContainerRef = this.captoChildQuestion.viewContainerRef
    viewContainerRef.clear()

    if (component) {
      const questionItem = new QuestionItem(
        component,
        this.question,
        this.question.responseSet
      )
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        questionItem.component
      )
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (componentRef.instance as QuestionChild).formId = this.formId;
      (componentRef.instance as QuestionChild).question = questionItem.question;
      (componentRef.instance as QuestionChild).response = questionItem.response;
      (componentRef.instance as QuestionChild).answerSelection.subscribe(a =>
        this.onAnswered(a)
      )
    } else {
      throw new Error(
        `[CAPTO ERROR]: "${
          this.question.type
        }" is not an allowed question type.`
      )
    }
  }

  onAnswered(answer: any): void {
    this.answerService.saveAnswer(this.formId, this.question.id, answer)
  }
}
