import * as LocaleActions from './locale.actions'

export type Action = LocaleActions.All

export const DEFAULT_LOCALE = 'fr'

export function localeReducer(state: string = DEFAULT_LOCALE, action: Action): string {
  switch (action.type) {
    case LocaleActions.SET_LOCALE:
      return action.payload
    default:
      return state
  }
}
