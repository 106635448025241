<div class="content">

  <h1 *ngIf="form?.type === formType.Consent" [innerHTML]="'app.title.consentFormStart' | translate:titleParam"></h1>
  <h1 *ngElse [innerHTML]="'app.title.formStart' | translate:titleParam"></h1>

  <div class="media">
    <div class="media__figure">
      <capto-form-icon [consent]="form?.type === formType.Consent" [code]="form?.shortname" [completed]="formState?.completed"></capto-form-icon>
    </div>
    <div class="media__body">
      <h2 class="u-h2">{{ form?.title }}</h2>
      <div [ngClass]="{'cms-content u-margin-top u-margin-bottom-huge': (form?.type === formType.Consent), 'u-h1': (form?.type !== formType.Consent)}"
        [innerHTML]="form?.description"></div>
      <p class="u-h3 u-margin-top" *ngIf="form?.type !== formType.Consent && form?.questions.length">
        { form?.questions.length, plural, =1 { {{ 'app.note.questionCount' | translate }} } other { {{
        'app.note.questionCountPlural'
        | translate:{count: form?.questions.length} }} } }</p>
    </div>
  </div>

</div>

<capto-pagenav [pause]="true" [prev]="{disabled: false}" [next]="{label: 'app.continue' | translate, showLabel: true, disabled: false}"></capto-pagenav>
