import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { range, set } from 'lodash'

import { QuestionChild } from '../question.component'
import { Question } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-level-vertical',
  templateUrl: './question-level-vertical.component.html',
  styleUrls: ['./question-level-vertical.component.scss']
})
export class QuestionLevelVerticalComponent implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  answerSelection: Subject<object> = new Subject()

  lowLevel
  highLevel
  range

  ngOnInit(): void {
    this.answer = this.question.answer
    this.lowLevel = this.response.values[0]
    this.highLevel = this.response.values[1]
    this.range = range(this.highLevel.value, this.lowLevel.value - 1)
  }

  save(value: any): void {
    this.answer = {
      values: { [this.question.id]: { id: this.question.id, value } },
      status: AnswerStatus.Answered
    }

    this.answerSelection.next(this.answer)
  }
}
