import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
  animate,
  style,
  transition,
  trigger,
  stagger,
  query
} from '@angular/animations'
import { findIndex, isUndefined, values } from 'lodash'
import { Question, QuestionId } from '../../models/question'
import { QuestionCategory } from '../../models/questionCategory'

@Component({
  selector: 'capto-progress',
  templateUrl: './progress.component.demo.html',
  styleUrls: ['./progress.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        query('span', style({ opacity: 0 }), { optional: true }),
        query(
          'span',
          stagger(150, [animate('.2s ease-in-out', style({ opacity: 1 }))]),
          { optional: true }
        )
      ])
    ])
  ]
})
export class ProgressComponent {
  steps = []
  @Input()
  formId: string
  @Input()
  formCode: string
  @Input()
  categories: QuestionCategory[]
  @Input()
  questions: Question[]

  // FIXME: fetch from Store?
  @Input()
  answers: object

  @Input()
  pageCompleted: boolean
  @Input()
  set currentQuestionNumber(questionNumber: number) {
    this.steps = []
    let questionsList = []
    if (this.questions) {
      questionsList = this.questions.map((q: Question) => {
        const categoryId = q.categoryId || null
        const current = q.itemNumber === questionNumber
        const completed =
          !isUndefined(this.answers) &&
          !isUndefined(this.answers[this.formId]) &&
          !isUndefined(this.answers[this.formId][q.id])
        return {
          id: q.id,
          number: q.itemNumber,
          type: 'question',
          categoryId,
          current: current,
          completed: completed
        }
      })
    }

    if (this.categories) {
      let categoriesList = []
      const catQuestions = []
      categoriesList = this.categories.map((c: QuestionCategory) =>
        ({
          id: c.id,
          number: c.index,
          type: 'category',
          current: false,
          completed: false
        }))
      questionsList.forEach(q => {
        if (!isUndefined(q.categoryId)) {
          const catIndex = findIndex(
            categoriesList,
            c => c.id === q.categoryId
          )
          if (catIndex > -1) {
            if (!categoriesList[catIndex].current) {
              categoriesList[catIndex].current = q.current
            }
            categoriesList[catIndex].completed = q.completed
            catQuestions[catIndex] = catQuestions[catIndex] || []
            catQuestions[catIndex].push(q)
          }
        }
      })
      const currentCatIndex = findIndex(categoriesList, c => c.current)
      if (currentCatIndex > -1) {
        categoriesList.splice(
          currentCatIndex + 1,
          0,
          ...catQuestions[currentCatIndex]
        )
      }
      this.steps = categoriesList
    } else {
      this.steps = questionsList
    }
  }

  @Output()
  readonly navigateTo: EventEmitter<any> = new EventEmitter()

  goToQuestion(questionId: QuestionId): void {
    this.navigateTo.emit(questionId)
  }
}
