import some from 'lodash/some'
import isUndefined from 'lodash/isUndefined'

import {
  Condition,
  Criterion,
  Item,
  QuestionId,
  Question,
  QuestionType,
  QuestionSubtype,
  QuestionOptions
} from './question'
import { QuestionCategoryId } from './questionCategory'
import { Answer } from './answer'

export class QuestionModel implements Question {
  id: QuestionId
  type: QuestionType
  subtype?: QuestionSubtype
  options?: QuestionOptions
  mandatory: boolean
  categoryId?: QuestionCategoryId
  title: object | string
  subtitle?: object | string
  hint?: object | string
  content?: object | string
  responseSet?: any
  items?: Item[]
  subquestions?: Question[]
  condition?: Condition // only exist on subquestion
  itemNumber?: number
  answer?: Answer

  constructor(_: any) {
    Object.assign(this, _)

    if (!isUndefined(this.subquestions)) {
      this.subquestions = this.subquestions.map(sq => new QuestionModel(sq))
    }
  }

  private isInline(): boolean {
    return (
      !isUndefined(this.condition) &&
      !isUndefined(this.condition.criteria) &&
      this.condition.criteria.length === 1 &&
      this.condition.inline
    )
  }

  criterionIsMet(criterion: Criterion, answer: Answer): boolean {
    return (
      !isUndefined(answer.values[criterion.id]) &&
      (isUndefined(criterion.value) ||
        answer.values[criterion.id].value === criterion.value) &&
      (isUndefined(criterion.pattern) ||
        RegExp(criterion.pattern, 'i').test(answer.values[criterion.id].value))
    )
  }

  isVisibleInline(itemId: string, answer: Answer): boolean {
    return (
      this.isInline() &&
      this.condition.criteria[0].id === itemId &&
      answer &&
      this.criterionIsMet(this.condition.criteria[0], answer)
    )
  }

  isVisibleAfter(answer: Answer): boolean {
    return (
      isUndefined(this.condition) ||
      isUndefined(this.condition.criteria) ||
      this.condition.criteria.length === 0 ||
      (!this.isInline() &&
        !!answer &&
        some(this.condition.criteria, criterion =>
          this.criterionIsMet(criterion, answer)
        ))
    )
  }
}
