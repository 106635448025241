import isUndefined from 'lodash/isUndefined'
import find from 'lodash/find'

import { Form, FormId, FormType, FormStatus } from './form'
import { QuestionCategory } from './questionCategory'
import { Question } from './question'
import { Response } from './response'

export class FormModel implements Form {
  id: FormId
  type: FormType
  status: FormStatus
  shortname: object | string
  title: object | string
  description: object | string
  categories: QuestionCategory[]
  questions: Question[]
  responses: Response[]

  constructor(_: Form) {
    Object.assign(this, _)

    if (!isUndefined(this.questions)) {
      let itemNumber = 1
      this.questions.forEach(question => {
        question.itemNumber = itemNumber
        itemNumber++
      })
    }
  }

  getCategory(id: any): QuestionCategory {
    return find(this.categories, cat => cat.id === id)
  }
}
