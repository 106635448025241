import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { cloneDeep, range, get, set } from 'lodash'

import { QuestionChild } from '../question.component'
import { Question } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-question-level',
  templateUrl: './question-level.component.html',
  styleUrls: ['./question-level.component.scss']
})
export class QuestionLevelComponent implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  answerSelection: Subject<object> = new Subject()

  lowLevel
  highLevel
  range

  levelAllEqual(): boolean {
    return get(this.question, 'options.level.allEqual', false)
  }

  ngOnInit(): void {
    this.answer = this.question.answer
    this.lowLevel = this.response.values[0]
    this.highLevel = this.response.values[1]
    this.range = range(this.lowLevel.value, this.highLevel.value + 1)
  }

  save(id: any, value: number): void {
    // use local var because we can’t mutate state
    const answer = cloneDeep(this.answer) || {}
    // toggle answer if it exist
    set(answer, ['values', id], { id, value })

    this.answer = answer
    this.answer.status = AnswerStatus.Answered

    this.answerSelection.next(answer)
  }
}
