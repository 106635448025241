import * as FormActions from './form.actions'
import { Form } from '../models/form'

export type Action = FormActions.All

export function formReducer(state: Form[] = [], action: Action): Form[] {
  switch (action.type) {
    case FormActions.LOAD_FORMS:
      return action.payload
    default:
      return state
  }
}
