import {
  Component,
  Inject,
  HostBinding,
  OnInit,
  OnDestroy
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { BehaviorSubject, Subject, EMPTY } from 'rxjs'
import { DOCUMENT } from '@angular/common'
import { takeUntil, switchMap } from 'rxjs/operators'
import { isEmpty, isUndefined } from 'lodash'
import { PageScrollService, PageScrollInstance } from 'ngx-page-scroll'

import { AppState } from '../../../store'
import { isFormComplete } from '../../../store/selector/forms.selector'
import { selectQuestion } from '../../../store/selector/question.selector'

import { routerTransition } from '../../../animation/router.animation'
import { AnswerService } from '../../../services/answer.service'
import { NavigationService } from '../../../services/navigation.service'
import { TaskService } from '../../../services/task.service'
import { ValidationService } from '../../../services/validation.service'

import { FormModel } from '../../../models/formModel'
import { PageQuestion, QuestionId } from '../../../models/question'
import { QuestionModel } from '../../../models/questionModel'
import { QuestionCategory } from '../../../models/questionCategory'
import { AnswerStatus } from '../../../models/answer'

@Component({
  selector: 'capto-page-question',
  templateUrl: './page-question.component.html',
  styleUrls: ['./page-question.component.scss'],
  animations: [routerTransition()]
})
export class PageQuestionComponent implements OnInit, OnDestroy {
  @HostBinding('@routerTransition')
  true

  answers = {} // used by progress component
  form: FormModel
  question: QuestionModel
  category: QuestionCategory = null
  isLastQuestion = false
  pageCompleted$ = new BehaviorSubject(false)

  private destroy$ = new Subject()

  constructor(
    private store: Store<AppState>,
    private answerService: AnswerService,
    private pageScrollService: PageScrollService,
    private navigationService: NavigationService,
    private taskService: TaskService,
    private validationService: ValidationService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectQuestion)
      )
      .subscribe(
        (
          {
            form = null,
            question = null,
            answers = null,
            isLastQuestion = null
          }: PageQuestion = { form, question, answers, isLastQuestion }
        ) => {
          if (question) {
            this.answers = answers
            this.form = form
            this.question = new QuestionModel(question)
            this.category = !isUndefined(question.categoryId)
              ? form.getCategory(question.categoryId)
              : null
            this.isLastQuestion = isLastQuestion

            const questionCompleted = this.validationService.isValidQuestion(
              this.form.id,
              this.question,
              answers
            )

            if (questionCompleted) {
              this.pageCompleted$.next(true)
              this.scrollToNav()
            } else {
              this.pageCompleted$.next(false)
            }

            if (!question.mandatory && isEmpty(question.answer)) {
              // initially mark question as SKIPPED; if user answer, this will be replaced
              this.answerService.saveAnswer(form.id, question.id, {
                values: {},
                status: AnswerStatus.Skipped
              })
            }
          }
        }
      )

    this.pageCompleted$
      .pipe(
        switchMap(
          pageComplete =>
            pageComplete
              ? this.store.pipe(
                  takeUntil(this.destroy$),
                  select(isFormComplete)
                )
              : EMPTY
        )
      )
      .subscribe(
        formComplete =>
          formComplete && this.form
            ? this.taskService.setFormCompleted(this.form.id)
            : null
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
  }

  // FIXME: TEMMPORARY FOR DEMO PURPOSE: used by progress to directly access a question
  onNavigateTo(questionId: QuestionId): void {
    this.navigationService.goToQuestion(this.form.id, questionId)
  }

  scrollToNav(): void {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(
      {
        document: this.document,
        scrollTarget: '#pagenav'
      }
    )
    this.pageScrollService.start(pageScrollInstance)
  }
}
