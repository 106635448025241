import * as DestinationActions from './destination.actions'
import { Destination } from '../models/destination'

export type Action = DestinationActions.All

export function destinationReducer(
  state: Destination = [],
  action: Action
): Destination {
  switch (action.type) {
    case DestinationActions.SET:
      return action.payload
    default:
      return state
  }
}
