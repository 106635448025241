import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { Store } from '@ngrx/store'
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service'

import { AppState } from '../store'
import * as LocaleActions from '../store/locale.actions'
import { LANG_STORAGE_KEY } from '../config/config'

// LocaleService is decorating TranslateService
@Injectable()
export class LocaleService {
  constructor(
    private store: Store<AppState>,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private translate: TranslateService
  ) {}

  get currentLang(): string {
    return this.translate.currentLang
  }

  use(lang: string): Observable<any> {
    // persist to localStorage
    this.storage.set(LANG_STORAGE_KEY, lang)

    // persist to store
    this.store.dispatch(new LocaleActions.SetLocale(lang))

    return this.translate.use(lang)
  }

  get(
    key: string | Array<string>,
    interpolateParams?: object
  ): Observable<string | any> {
    return this.translate.get(key, interpolateParams)
  }

  getLangs(): Array<string> {
    return this.translate.langs
  }

  toggle(): void {
    const lang = this.currentLang === 'fr' ? 'en' : 'fr'
    this.use(lang)
  }
}
