import { Params } from '@angular/router'
import { FormId } from './form'
import { QuestionId } from './question'

export enum routeSegment {
  connection = 'connexion',
  complete = 'complete',
  fin = 'fin',
  question = 'question',
  questionnaire = 'questionnaire',
  questionnaires = 'questionnaires'
}

export interface RouterState {
  url: string
  params: Params
  queryParams: Params
}

export interface RouteParams {
  code: any
  formId: FormId
  questionId: QuestionId
}
