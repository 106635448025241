import { Action } from '@ngrx/store'

export const LOAD_TASK = 'LoadTask'

export class LoadTask implements Action {
  readonly type = LOAD_TASK
  constructor(public payload) {}
}

export type All = LoadTask
