import { camelCase } from 'lodash'

import { QuestionGraphicExclusiveComponent } from './graphic-exclusive/question-graphic-exclusive.component'
import { QuestionGraphicMultipleComponent } from './graphic-multiple/question-graphic-multiple.component'
import { QuestionLevelComponent } from './level/question-level.component'
import { QuestionLevelNamedComponent } from './level-named/question-level-named.component'
import { QuestionLevelVerticalComponent } from './level-vertical/question-level-vertical.component'
import { QuestionListExclusiveComponent } from './list-exclusive/question-list-exclusive.component'
import { QuestionListMultipleComponent } from './list-multiple/question-list-multiple.component'
import { QuestionNumberComponent } from './number/question-number.component'
import { QuestionTextComponent } from './text/question-text.component'
import { QuestionYesNoComponent } from './yes-no/question-yes-no.component'

import { QuestionType } from '../../models/question'

export class QuestionFactory {
  static createQuestion(type: QuestionType): any {
    switch (type) {
      case QuestionType.GraphicExclusive:
        return QuestionGraphicExclusiveComponent
      case QuestionType.GraphicMultiple:
        return QuestionGraphicMultipleComponent
      case QuestionType.Level:
        return QuestionLevelComponent
      case QuestionType.LevelVertical:
        return QuestionLevelVerticalComponent
      case QuestionType.LevelNamed:
        return QuestionLevelNamedComponent
      case QuestionType.ListExclusive:
        return QuestionListExclusiveComponent
      case QuestionType.ListMultiple:
        return QuestionListMultipleComponent
      case QuestionType.Number:
        return QuestionNumberComponent
      case QuestionType.Text:
        return QuestionTextComponent
      case QuestionType.YesNo:
        return QuestionYesNoComponent
      default:
        // unknown type
        return null
    }
  }
}
