<div class="passcode">
  <div class="passcode__header">
    <h1>{{ 'app.enterCode' | translate }}</h1>
    <div class="passcode__digits">
      <span class="passcode__digit"><span *ngIf="code.length >= 1" [@digit]="code.length === 1">{{ code[0] }}</span></span>
      <span class="passcode__digit"><span *ngIf="code.length >= 2" [@digit]="code.length === 2">{{ code[1] }}</span></span>
      <span class="passcode__digit"><span *ngIf="code.length >= 3" [@digit]="code.length === 3">{{ code[2] }}</span></span>
      <span class="passcode__digit"><span *ngIf="code.length >= 4" [@digit]="code.length === 4">{{ code[3] }}</span></span>
      <span class="passcode__digit"><span *ngIf="code.length >= 5" [@digit]="code.length === 5">{{ code[4] }}</span></span>
      <span class="passcode__digit"><span *ngIf="code.length >= 6" [@digit]="code.length === 6">{{ code[5] }}</span></span>
      <button mat-icon-button class="passcode__clear" *ngIf="code.length" [@code]="code.length" (click)="clearCode()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="passcode__error" *ngIf="error">{{ error | translate }}</div>
    </div>
  </div>
  <fieldset class="passcode__keypad" [disabled]="code.length === codeLength">
    <div class="passcode__row">
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(1)"><span
            class="button__label button__label--icon button__label--lg">1</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(2)"><span
            class="button__label button__label--icon button__label--lg">2</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(3)"><span
            class="button__label button__label--icon button__label--lg">3</span></button></div>
    </div>
    <div class="passcode__row">
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(4)"><span
            class="button__label button__label--icon button__label--lg">4</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(5)"><span
            class="button__label button__label--icon button__label--lg">5</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(6)"><span
            class="button__label button__label--icon button__label--lg">6</span></button></div>
    </div>
    <div class="passcode__row">
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(7)"><span
            class="button__label button__label--icon button__label--lg">7</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(8)"><span
            class="button__label button__label--icon button__label--lg">8</span></button></div>
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(9)"><span
            class="button__label button__label--icon button__label--lg">9</span></button></div>
    </div>
    <div class="passcode__row">
      <div class="passcode__key"><button mat-button class="button--round button--outline" (click)="inputDigit(0)"><span
            class="button__label button__label--icon button__label--lg">0</span></button></div>
    </div>
  </fieldset>
</div>
