import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { cloneDeep, isUndefined, get, set } from 'lodash'

import { QuestionChild } from '../question.component'
import { Question } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-question-list-multiple',
  templateUrl: './question-list-multiple.component.html',
  styleUrls: ['./question-list-multiple.component.scss']
})
export class QuestionListMultipleComponent implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  answerSelection: Subject<object> = new Subject()

  listInline(): boolean {
    return get(this.question, 'options.list.inline', false)
  }

  ngOnInit(): void {
    this.answer = this.question.answer
  }

  save(id: any): void {
    // use local var because we can’t mutate state
    const answer = cloneDeep(this.answer) || {}
    // toggle answer if it exist
    if (!isUndefined(answer.values) && answer.values.hasOwnProperty(id)) {
      delete answer.values[id]
    } else {
      set(answer, ['values', id], { id, value: id })
    }
    if (Object.keys(answer.values).length) {
      this.answer = answer
      this.answer.status = AnswerStatus.Answered
    } else {
      this.answer = undefined
    }
    this.answerSelection.next(answer)
  }
}
