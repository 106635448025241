import { Component, HostListener } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations'

import { AppState } from '../../store'
import * as DestinationActions from '../../store/destination.actions'
import { TaskService } from '../../services/task.service'
import { routeSegment } from '../../models/routerState'

@Component({
  selector: 'capto-passcode',
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.scss'],
  animations: [
    trigger('digit', [
      state('active', style({ opacity: 1, transform: 'scale(1)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.5)' }),
        animate('.1s ease-in-out')
      ])
    ]),
    trigger('code', [
      state('active', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate('.2s ease-in-out')])
    ]),
    trigger('welcome', [
      state('active', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate('.2s ease-in-out')])
    ])
  ]
})
export class PasscodeComponent {
  codeLength = 6
  code: number[] = []
  error = ''

  constructor(
    private store: Store<AppState>,
    private taskService: TaskService
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) || // keyboard numeric keys
      (event.keyCode >= 96 && event.keyCode <= 105) // keypad numeric keys
    ) {
      this.inputDigit(+event.key)
    } else if ([8, 12, 27, 46].indexOf(event.keyCode) > -1) {
      // backspace, keypad clear, escape, delete
      this.code.pop()
    }
  }

  inputDigit(digit: number): void {
    this.code.push(digit)
    if (this.code.length === this.codeLength) {
      this.validateCode()
    }
  }

  clearCode(): void {
    this.code = []
    this.error = ''
  }

  validateCode(): void {
    const code = this.code.join('')
    this.taskService.loadTask(code).subscribe(
      () => {
        this.store.dispatch(
          new DestinationActions.Set([code, routeSegment.questionnaires])
        )
      },
      error => {
        console.log(error)
        this.error = 'app.error.invalidCode'
        setTimeout((): void => {
          this.clearCode()
        }, 2000)
      }
    )
  }
}
