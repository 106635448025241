<div class="progress">
  <div class="progress__formcode">{{ formCode }}</div>
  <div class="progress__bar" [@listAnimation]="steps.length">
    <ng-container *ngFor="let step of steps">
      <ng-container *ngIf="step.type === 'question'; then thenBlock else elseBlock"></ng-container>

      <ng-template #thenBlock>
        <button class="progress__step progress__step--question" [ngClass]="{'is-completed': step.completed || (step.current && pageCompleted), 'is-current': step.current}"
          (click)="goToQuestion(step.id)">
          {{ step.number }}
        </button>
      </ng-template>

      <ng-template #elseBlock>
        <div class="progress__step progress__step--category" [ngClass]="{'is-completed': step.completed || (step.current && pageCompleted), 'is-current': step.current}">
          {{ step.number }}
        </div>
      </ng-template>

    </ng-container>
  </div>
</div>
