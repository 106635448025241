import { Action } from '@ngrx/store'
import { Destination } from '../models/destination'

export const SET = 'Set'

export class Set implements Action {
  readonly type = SET
  constructor(public payload: Destination) {}
}

export type All = Set
