import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { Options as sliderOptions } from 'ng5-slider'

import { QuestionChild } from '../question.component'
import { Question } from '../../../models/question'
import { Response } from '../../../models/response'
import { AnswerStatus, Answer } from '../../../models/answer'

@Component({
  selector: 'capto-question-number',
  templateUrl: './question-number.component.html',
  styleUrls: ['./question-number.component.scss']
})
export class QuestionNumberComponent implements QuestionChild, OnInit {
  @Input()
  formId: string
  @Input()
  question: Question
  @Input()
  response: Response
  answer: Answer
  answerSelection: Subject<object> = new Subject()

  options: sliderOptions

  private _value: number = null
  valueSubject: Subject<number> = new Subject()
  get value(): number {
    return this._value
  }
  set value(num: number) {
    this._value = num
    this.valueSubject.next(num)
  }

  ngOnInit(): void {
    this.answer = this.question.answer
    this.options = {
      floor: this.question.options.number.min || 0,
      ceil: this.question.options.number.max || 100,
      showTicks: true
    }
    this.options.tickStep = Math.round(this.options.ceil / 10)
    this._value = this.answer
      ? this.answer.values[this.question.id].value
      : null
    // we debounce to avoid saving on every slider increment
    this.valueSubject
      .pipe(debounceTime(300))
      .subscribe(value => this.save(value))
  }

  initValue(): void {
    if (this.value === null) {
      this.value = this.options.floor
    }
  }

  save(value: number): void {
    value = null ? this.options.floor : value
    this.answer = {
      values: { [this.question.id]: { id: this.question.id, value } },
      status: AnswerStatus.Answered
    }

    this.answerSelection.next(this.answer)
  }
}
