import { Injectable } from '@angular/core'
import { filter, get, isEmpty, isUndefined, some, isNil } from 'lodash'

import { Question, QuestionType, QuestionSubtype } from '../models/question'
import { QuestionModel } from '../models/questionModel'
import { Answer, FormAnswers } from '../models/answer'

@Injectable()
export class ValidationService {
  // TODO: refactor validation process
  private isValidQuestionItem(question: Question, answer: Answer): boolean {
    if (
      question.type === QuestionType.YesNo &&
      question.subtype === QuestionSubtype.Checkbox
    ) {
      return answer === 1
    }
    return !isNil(answer) && answer !== ''
  }

  // isValid({
  //   question,
  //   answer
  // }: {
  //   question: Question;
  //   answer?: Answer;
  // }): boolean {
  //   return false
  // }

  isValidQuestion(
    formId: any,
    question: QuestionModel,
    answers: FormAnswers
  ): boolean {
    let questionCompleted: any = true
    let subquestionsCompleted: any = true

    if (!isUndefined(question.items)) {
      const filteredItemsCompleted = filter(question.items, item => {
        const answerObj = get(answers, [
          formId,
          question.id,
          'values',
          item.id,
          'value'
        ])
        return this.isValidQuestionItem(question, answerObj)
      })
      questionCompleted =
        filteredItemsCompleted.length === question.items.length
    } else {
      // straight up question without items
      const answerObj = get(answers, [formId, question.id, 'values'])
      questionCompleted = this.isValidQuestionItem(question, answerObj)
    }

    if (!isUndefined(question.subquestions)) {
      const mandatorySubquestions = filter(question.subquestions, sq => {
        return (
          sq.mandatory === true &&
          (isUndefined(sq.condition.criteria) ||
            sq.condition.criteria.length === 0 ||
            some(sq.condition.criteria, criterion => {
              const answerObj = get(answers, [formId, question.id], undefined)
              return !isUndefined(answerObj) &&
                question.criterionIsMet(criterion, answerObj)
                ? answerObj
                : null
            }))
        )
      })
      const filteredSubquestionsCompleted = filter(
        mandatorySubquestions,
        item => !isEmpty(get(answers, [formId, item.id, 'values']))
      )
      subquestionsCompleted =
        filteredSubquestionsCompleted.length === mandatorySubquestions.length
    }

    return questionCompleted && subquestionsCompleted
  }
}
