<div class="u-margin-top">
  <div class="number" [ngClass]="{'number--active': value !== null}">
    <div class="number__value">{{ value !== null ? value : '&nbsp;' }}</div>
    <ng5-slider [(value)]="value" [options]="options" (userChangeStart)="initValue()"></ng5-slider>
  </div>
</div>


<ng-container *ngFor="let sq of question.subquestions">
  <div *ngIf="sq.isVisibleAfter(answer)" class="subquestion">
    <capto-question showTitle="true" [formId]="formId" [question]="sq"></capto-question>
  </div>
</ng-container>
