import { AfterViewInit, Component, HostBinding } from '@angular/core'

@Component({
  selector: 'capto-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements AfterViewInit {
  showPasscode = false

  @HostBinding('class.page-wrapper')
  true

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showPasscode = true
    }, 2000)
  }
}
