import { Directive, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[captoQuestionChild]'
})
export class QuestionChildDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
